import React from 'react';

const CategoryItem = ({ data, goNext }) => {
  return (
    <>
      <div
        className="category-item"
        onClick={() => {
          goNext(data.id);
        }}
      >
        <div className="image">
          <img src={data.image} alt="" />
        </div>
        <div className="name">{data.name}</div>
      </div>
      <style jsx>{`
        .category-item {
          width: calc((100vw - 48px) / 3);
          height: 100%;
          box-sizing: border-box;
          padding: 5px 0;
          background: #ffffff;
          border-radius: 10px;
          .image {
            width: 52.3%;
            height: 0;
            padding-bottom: 52.3%;
            margin: 0 auto;
            img {
              width: 100%;
            }
          }
          .name {
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: #3b3516;
            margin: 7px auto 0 auto;
            height: 40px;
          }
        }
      `}</style>
    </>
  );
};

export default CategoryItem;
