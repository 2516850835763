import React, { useEffect, useState } from 'react';

import HeaderBar from '../../components/header-bar/index.jsx';
import CategoryItem from '../../components/category-item/index.jsx';
import { navigate } from 'gatsby';
import { useAPI } from '../../utils/api';
import { callAppFunc } from '../../utils/jsbridge/index.js';
import { showLoading, dismissLoading } from '../../utils/loading';
import { pageCheck } from '../../utils/pageCheck';
import { useSelector } from 'react-redux';
import store, { actions, getters } from '../../redux/store';
import { setSelectProductList } from '../../utils/_ga';

const CategoryList = () => {
  const api = useAPI();
  const currentStoreId = useSelector((state) => getters.getStoreId(state));
  const localCacheData = useSelector((state) => getters.getLocalCache(state));

  const PAGE_CACHE_KEY = 'categoryList';

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const categoryCache = localCacheData[`${PAGE_CACHE_KEY}-${currentStoreId}`];
    if (categoryCache !== undefined && categoryCache !== null) {
      if (categoryCache.length > 0) {
        setCategories(categoryCache);

        return;
      }
    }
    // showLoading();
    api
      .getCategories(currentStoreId)
      .then((res) => {
        // dismissLoading();
        if (!res) {
          console.log('run store close');
          callAppFunc('storeClose', {});
          return;
        }

        if (res.length > 0) {
          setCategories(res);
        }
      })
      .catch((error) => {
        // dismissLoading();
        console.log(error);
      });
  }, []);

  return (
    <>
      <div className="category-list">
        <div className="header">
          <HeaderBar
            title={'所有分類'}
            goBack={() => {
              callAppFunc('backHome', {});
            }}
          />
        </div>
        <div className="category-block">
          {categories &&
            categories.map((category, index) => (
              <div className="category-item" key={index}>
                <CategoryItem
                  data={category}
                  goNext={(id) => {
                    pageCheck(api, () => {
                      const cacheData = {
                        key: `${PAGE_CACHE_KEY}-${currentStoreId}`,
                        data: categories,
                      };
                      store.dispatch(actions.addLocalCache(cacheData));
                      setSelectProductList(
                        category.id,
                        category.name,
                        '所有分類頁'
                      );
                      navigate(`/category/product/${id}`, {
                        state: {},
                        replace: false,
                      });
                    });
                  }}
                />
              </div>
            ))}
        </div>
      </div>
      <style jsx>{`
        .category-list {
          min-height: 100vh;
          background: #f2f2f2;
          padding: 70px 16px 30px 16px;
          box-sizing: border-box;
          .header {
            width: 100vw;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 999;
          }
          .category-block {
            display: flex;
            flex-shrink: 0;
            flex-wrap: wrap;
            .category-item {
              margin-bottom: 8px;
              margin-right: 8px;
            }
            div:nth-child(3n) {
              margin-right: 0px;
            }
          }
        }
      `}</style>
    </>
  );
};

export default CategoryList;
